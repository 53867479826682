import React, { useState, useEffect, useRef } from "react";
import "./AllAdminsTable.scss";
import { Link } from "react-router-dom";
import { FcPlus } from "react-icons/fc";
import { FetchingAdmins } from "../../../Redux/Actions/AdminActions/FetchingAdmins";
import { AddingNewAdmin } from "../../../Redux/Actions/AdminActions/AddingNewAdmin";
import { ChangeSnackbar } from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import { CircularProgress } from "@material-ui/core";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useAppSelector, useAppThunkDispatch } from "../../../Redux/hooks";
import { AdminInterFace } from "../../../Components/FeedComponents/Feed/Feed";
import toast from "react-hot-toast";
import { mailChecker } from "../../../helpers/HelperFunction";
import { Typography } from "@mui/material";
import { CellDataType, Masjid } from "../../../../Types";
import MasjidsAutocomplete from "../../../Pages/Shared/MasjidsAutocomplete/MasjidsAutocomplete";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import { UpdateMasjidInAdminById } from "../../../Redux/Actions/AdminActions/UpdatingAdminMasjidById";
import { handleSnackbar } from "../../../helpers/SnackbarHelper/SnackbarHelper";
import { useQuery } from "@apollo/client";
import { GetUsers } from "../../../graphql/queries";

const AdminColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Admin Name",
    width: 180,
    renderCell: (rowData: any) => {
      return (
        <>
          <Link to={"/admins/" + rowData.row._id} style={{ color: "#054635" }}>
            {rowData.row.name}
          </Link>
        </>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 380,
  },

  {
    field: "role",
    headerName: "Role",
    width: 150,
    renderCell: (params: CellDataType) => {
      const role = params.row.role;
      const displayRole = roleRenamer(role);
      return <Typography variant="body1">{displayRole}</Typography>;
    },
  },

  {
    field: "isVerified",
    headerName: "Verification Status",
    width: 140,
    renderCell: (rowData: any) => (
      <div>
        <span>{rowData?.row.isVerified ? "done" : "pending"}</span>
      </div>
    ),
  },
  {
    field: "Managed Masjid ",
    headerName: "Managed Masjid",
    width: 280,
    renderCell: (rowData: any) => {
      return (
        <div>
          <span>{rowData.row.masjidDetails[0]?.masjidName}</span>
        </div>
      );
    },
  },
];

const AllAdminsTable = () => {
  const dispatch = useAppThunkDispatch();
  const allAdmins = useAppSelector((state) => state.allAdmins);
  const [Admins, setAdmins] = useState([]);
  const [OpenModal, setOpenModal] = useState(false);
  const [AdminRole, setAdminRole] = useState("");
  const [masjidId, setMasjidId] = useState("");
  let AdminNameRef = useRef<HTMLInputElement | null>(null);
  let AdminEmailRef = useRef<HTMLInputElement | null>(null);
  const [isFetching, setisFetching] = useState(false);
  const adminString = localStorage.getItem("admin");
  const admin: AdminInterFace | null = adminString
    ? JSON.parse(adminString)
    : null;

  const handleAddAdminModal = () => {
    setOpenModal(true);
  };

  const handleAddAdminModalClose = () => {
    setOpenModal(false);
  };
  const limit = 1000;
  const offset = 1000;
  const { loading, error, data } = useQuery(GetUsers, {
    variables: { limit, offset },
  });
  console.log("data  => ", data?.users);

  useEffect(() => {
    if (data && data?.users) setAdmins(data?.users);
  }, [data]);
  useEffect(() => {
    const response = dispatch(FetchingAdmins());
    response.then(function (result) {
      if (result.success) {
        // setAdmins(result.data.data);
      } else {
        const snackbarFailureDetails = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Fetching Admins Failed",
        };
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    });
  }, []);
  const handleUpdationAdmin = async (addingUserId: string) => {
    let uploadData = {
      user: addingUserId,
      masjidId: masjidId,
    };

    try {
      const result = await dispatch(UpdateMasjidInAdminById(uploadData));
      return result.success;
    } catch (error) {
      console.error("Error updating admin:", error);
      return false;
    }
  };

  const addAdmin = async () => {
    const email = AdminEmailRef.current?.value ?? "";

    if (!mailChecker(email)) return;

    let updatedData = {
      name: AdminNameRef.current?.value ?? "",
      email,
      role: AdminRole,
    };

    setisFetching(true);

    try {
      const result = await dispatch(AddingNewAdmin(updatedData));
      if (result?.success) {
        if (AdminRole === "subadmin" || AdminRole === "musaliadmin") {
          console.log("result.data => ", result.data);
          await handleUpdationAdmin(result.data._id);
        }
        handleAddAdminModalClose();

        const fetchResult = await dispatch(FetchingAdmins());
        if (fetchResult.success) {
          setAdmins(fetchResult.data.data);
        } else {
          const snackbarFailureDetails = {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to fetch admins",
          };
          dispatch(ChangeSnackbar(snackbarFailureDetails));
        }

        const snackbarSuccessDetails = {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Admin credentials have been sent to the admin",
        };
        dispatch(ChangeSnackbar(snackbarSuccessDetails));
      } else {
        const snackbarFailureDetails = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Failed to add admin",
        };
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    } catch (error) {
      console.error("Error adding admin:", error);
      const snackbarFailureDetails = {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Error adding admin",
      };
      dispatch(ChangeSnackbar(snackbarFailureDetails));
    } finally {
      setisFetching(false);
    }
  };

  const updateAdminRole = (e: SelectChangeEvent<string>) => {
    setAdminRole(e.target.value);
  };

  const getRowClassName = () => {
    return "custom-cell";
  };
  const handleChange = (info: Masjid) => {
    setMasjidId(info._id);
  };

  return (
    <div className="datatable">
      <div className="titleHead">
        <div className="datatableTitle">
          {isFetching && (
            <>
              <span className="LoadingMessage">
                Adding The Admin Data{" "}
                <CircularProgress
                  size="20px"
                  style={{ marginLeft: "10px", marginTop: "5px" }}
                />
              </span>
            </>
          )}
          {(admin?.role === "superadmin" || admin?.role === "admin") && (
            <Button
              className="link"
              onClick={handleAddAdminModal}
              disabled={isFetching}
            >
              {isFetching ? (
                <CircularProgress
                  //  color="black"
                  size="15px"
                />
              ) : (
                <>
                  <span> Add New Admin </span>
                  <FcPlus className="PlusIcon" />
                </>
              )}
            </Button>
          )}
        </div>
      </div>
      <Dialog
        open={OpenModal}
        onClose={handleAddAdminModal}
        //  height={"xl"}
      >
        <DialogTitle>New Admin</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Admin Name"
            placeholder="Admin Name"
            sx={{ marginBottom: 3 }}
            type="text"
            fullWidth
            inputRef={AdminNameRef}
            variant="standard"
          />
          <TextField
            id="outlined-required"
            variant="standard"
            label="Admin Email"
            placeholder="Admin Email"
            fullWidth
            inputRef={AdminEmailRef}
            type="email"
            sx={{ marginBottom: 3 }}
          />

          <FormControl fullWidth style={{ marginBottom: "10px" }}>
            <InputLabel id="demo-simple-select-label">Admin Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Admin Role"
              value={AdminRole}
              onChange={updateAdminRole}
            >
              <MenuItem value={"admin"}>Data Entry</MenuItem>
              <MenuItem value={"subadmin"}>Masjid Admin</MenuItem>
              <MenuItem value={"musaliadmin"}>Musali Admin</MenuItem>
            </Select>
          </FormControl>
          {AdminRole === "subadmin" || AdminRole === "musaliadmin" ? (
            <MasjidsAutocomplete handleChange={handleChange} />
          ) : null}
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button onClick={handleAddAdminModalClose} style={{ color: "grey" }}>
            Cancel
          </Button>
          <Button
            data-testid="add-button"
            onClick={addAdmin}
            disabled={isFetching}
          >
            {" "}
            Add{" "}
          </Button>
        </DialogActions>
      </Dialog>
      <div className="admin-page-main-table ">
        <DataGrid
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          loading={Admins.length <= 0}
          rows={Admins}
          columns={AdminColumns}
          pageSize={12}
          getRowId={(row) => row._id}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          getRowClassName={getRowClassName}
          // getHeaderRowClassName={getHeaderRowClassName}
        />
      </div>
    </div>
  );
};

export default AllAdminsTable;
